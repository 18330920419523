/* :root{
  --dark: #42273b;
  --mid-dark: #70566d;
  --mid: #70566d;
  --mid-light: #70566d;
  --light: #70566d;
  --light-text: white;
  --dark-text: black;
} */

:root{
  --dark: #42273b;
  --mid-dark: #503d4e;
  --mid-mid-dark: #685665;
  --mid: #826c7f;
  --mid-light: #a88fac;
  --light: #d4b2d8;
  --lighter: #f7dffc;
  --lightest: #fbe1ff;
  --light-text: white;
  --dark-text: #1d1019;
}

@keyframes purple-breath {
  from {background-color: var(--dark);}
  to {background-color: var(--mid-dark);}
}

/* @keyframes purple-breath {
  0% {background-image: linear-gradient(90deg, red 0%, orange 20%, yellow 40%, green 60%, blue 80%, purple 100%);}
  20% {background-image: linear-gradient(90deg, orange 0%, yellow 20%, green 40%, blue 60%, purple 80%, red 100%);}
  40% {background-image: linear-gradient(90deg, yellow 0%, green 20%, blue 40%, purple 60%, red 80%, orange 100%);}
  60% {background-image: linear-gradient(90deg, green 0%, blue 20%, purple 40%, red 60%, orange 80%, yellow 100%);}
  80% {background-image: linear-gradient(90deg, blue 0%, purple 20%, red 40%, orange 60%, yellow 80%, green 100%);}
  100% {background-image: linear-gradient(90deg, purple 0%, red 20%, orange 40%, yellow 60%, green 80%, blue 100%);}
} */

body {
  background-color: var(--dark);
  overflow-x: hidden;
}

.App {
  text-align: center;
  overflow-x: hidden;
}

.App-header {
  /* background-color: rgb(57, 19, 68); */
  background-color: none;
  min-height: 100vh;
  max-width: 100%;
  /* display: grid; */
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: var(--light-text);
  overflow: hidden;
}

.App-header div {
  border-radius: 45px;
}

.Main-div {
  height: 100%;
}

.Title-div {
  background-color: var(--dark);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  /* animation-name: purple-breath;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate; */
}

.Title-div div {
  display: flex;
  align-items: center;
  justify-content: center;
}


.Navigation-bar {
  position: absolute;
  top: 70vh;
  z-index: 6;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.Navigation-bar a {
  margin: 0 10px 0 10px;
  color: var(--light-text);
  text-decoration: none;
  font-size: calc(50px);
}

.Navigation-bar a:visited {
  color: var(--light-text);
}

.Navigation-bar a:hover {
  color: var(--mid);
  text-shadow: 0px 0px 4px var(--mid);
}

.Navigation-bar a:active {
  border: none;
}

.Navigation-bar p {
  margin: 2px;
}

@media only screen and (max-width: 1409px) {
  .Navigation-bar {
    flex-direction: column;
    top: 70vh;
  }

  .Navigation-bar a {
    font-size: calc(30px + 0.5vmin);
  }
  
  .Navigation-bar p {
    display: none;
  }
}

.Blurb-container {
  background-color: var(--mid-mid-dark);
  width: 95vw;
  margin-bottom: 5vmin;
  color: var(--dark-text);
}

.Blurb-div {
  background-color: var(--mid-mid-dark);
  width: 100%;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-flow: wrap;
  font-size: 0.9em;
}

.Blurb-div img {
  /* width: calc(128px + 10vmin);
  height: calc(128px + 10vmin); */
  width: 50vmin;
  box-shadow: 0 0 15px var(--dark);
}

@media only screen and (max-width: 1409px) {
  .Blurb-div img {
    margin-top: 5vmin;
  }
}
.Experience-container {
  background-color: var(--mid);
  color: var(--dark-text);
  width: 95vw;
  margin-bottom: 5vmin;
}

.Experience-div {
  text-align: left;
  display: flex;
  justify-content: center;
}

.Experience-div span {
  font-size: 4vmin;
  font-weight: bold;
  color:var(--dark);
}

.Experience-div sup {
  font-size: 3vmin;
}

.Experience-div li {
  font-size: 2.5vmin;
}

.Education-container {
  background-color: var(--mid-light);
  color: var(--dark-text);
  width: 95vw;
  margin-bottom: 5vmin;
}

.Education-div {
  text-align: left;
  display: flex;
  justify-content: center;
}

.Education-div span {
  font-size: 4vmin;
  font-weight: bold;
  color: var(--dark);
}

.Education-div sup {
  font-size: 3vmin;
}

.Education-div li {
  font-size: 2.5vmin;
}

.Skills-container {
  background-color: (--mid-dark);
  width: 95vw;
}

.Skills-div {
  background-color: none;
  width: 100%;
  /* min-height: 50vh; */
  display: flex;
  justify-content: space-between;
  /* align-items: flex-start; */
  flex-flow: wrap;
  color: var(--dark-text);
}

.Skills-div div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 48.5%;
  margin-bottom: 5vmin;
}

@media only screen and (max-width: 1300px) {
  .Skills-div div {
    max-width: none;
  }
}

.Skills-div div div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-flow: wrap;
  max-width: 100%;
}

.Skill {
  position: relative;
  width: 10vmin;
  height: 10vmin;
  margin: 5vmin 5vmin 5vmin 5vmin;
  padding-bottom: 3vmin;
  color: var(--dark-text);
  font-weight: bold;
}

.Skill .Skill-logo {
  position: relative;
  width: 90%;
  height: 90%;
  transition: all;
  transition-duration: 0.4s;
}

.Skill:hover .Skill-logo {
  width: 100%;
  height: 100%;
  transform: rotateY(360deg);
}

.Skill .Star {
  /* position: absolute;
  top: -50px;
  width: 300%;
  height: 300%; */
}

.Link-container {
  background-color: var(--lightest);
  color: var(--dark-text);
  width: 95vw;
  min-height: 37vmin;
  margin-bottom: 5vmin;
}

.Link-div {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 15vmin;
}

.Link-div a {
  width: 15vmin;
  height: 15vmin;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.Link {
  height: 10vmin;
  width: 10vmin;
  transition: all 1s;
}

.Link:hover {
  height: 15vmin;
  width: 15vmin;
}

.Link img{
  height: 100%;
  width: 100%;
}

.Resume-container {
  width: 95vw;
  display: flex;
  justify-content: center;
  margin-bottom: 5vmin;
  background-color: white;
  border-radius: 0!important;
}

@keyframes flicker-anim {
  0%, 15%, 23%, 34%, 47%, 53%, 69%, 75%, 82%, 96%, 100% {
    /* text-shadow: 1px 1px 5px black; */
    text-shadow: none;
  }
  26% {
    text-shadow: 2px 2px 10px var(--light-text);
    /* transform: translateY(10px); */
  }
}

.Flicker {
  animation-name: flicker-anim;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(.95,.05,.8,.04);
  /* animation-direction: alternate; */
}

.Circle {
  position: absolute;
  height: 100vmin;
  width: 100vmin;
  z-index: 1;
  /* background-color: royalblue; */
  background-color: none;
}

.Circle div {
  /* background-color: saddlebrown; */
  background-color: none;
  border-radius: 50%;
  width: 80%;
  height: 80%;
  position: relative;
}

@keyframes Spin {
  0% {transform: rotateZ(0deg) translateX(-50vmin); background-color: rgba(0,0,0,0.1);}
  100% {transform: rotateZ(360deg) translateX(-50vmin); background-color: rgba(0,0,0,0.1);}
}

.Circle div div {
  background-color: none;
  border-radius: 50%;
  width: 5%;
  height: 5%;
  position: absolute;
  animation-name: Spin;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 0s;
  }
